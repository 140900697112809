<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="px-5 py-4">
              <b-card-body>
                <!-- <h1 class="mb-4">Login</h1> -->
                <img class="m-auto d-block" src="img/brand/logo.png" width="160" height="40" alt="Logo">        
                <b-row>
                    <ResponseAlert ref="response" />
                </b-row>
                <form @submit.prevent="send" class="needs-validation mt-5" novalidate>
                  <div class="form-group" :class="{'has-error': errors.has('username') }">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon-user"></i></div>
                      </div>
                      <input v-model="models.email" 
                              v-validate="'required'" 
                              name="email" 
                              class="form-control" 
                              type="text" 
                              placeholder="Email">
                    </div>
                    <div class="help-block" v-if="errors.has('email')">
                      <p class="text-danger">{{ errors.first('email') }}</p>
                    </div>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-button type="submit" variant="primary" class="px-4 btn-block">Send</b-button>
                    </b-col>
                    <b-col cols="12" class="text-center">
                      <b-button to="login" variant="link" class="">Back to login</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ResponseAlert from '@/components/response_alert';
import api from '@/utils/api.js';

export default {
  name: 'ForgotPassword',
  components: {
    ResponseAlert,
  },
  data() {
    return {
      models : {
        email: ''
      },
    }
  },
  methods : {
    send(){
      const _ = this;
      _.$validator.validate().then(valid => {
        if (valid) {
          _.$axios.post(api.forgot_password,_.models)
          .then(() => {
            this.$showToast('Success', 'Succesfully send link reset password to your email')
            _.$router.push('/login')
          })
          .catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
        }
      });
    }
  }
}
</script>